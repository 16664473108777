<template lang="pug">

.nav-cont
  //- router-link.nav-cont__calendar(:to="{name: $store.state.general.lang+'.home'}")
  //-   span Ir al calendario
  //-   i: font-awesome-icon(icon="th")
  .nav-opt(
    v-if="round_list.length"
    role="button" 
    v-for="round in round_list"
    :key="round.id" 
    @click="goNavDay(round)"
    :class="{'nav-opt--current': isActualCompRound(round), 'nav-opt--sel': isActualRound(round)}"
    )
    span {{ parsedDescriptor(round.descriptor) }}
    i
      font-awesome-icon(icon="chevron-right")
  .nav-opt(v-else)
    span {{ $t('Not defined')}}
</template>

<script>
export default {
  name: "NavRound",
  props: {
    round_list: Array,
    actual: Object
  },
  methods: {
    parsedDescriptor(descriptor) {
      // translate descriptor (can't be translated using regular module)
      if (this.$store.state.general.lang == 'en') {
        if (descriptor.includes('Jornada')) {
          return descriptor.replace('Jornada', 'Round') // Matchweek instead of round?
        }
        else if (descriptor.includes('1/8 de final')) {
          return descriptor.replace('1/8 de final', 'Last 16')
        }
        else if (descriptor.includes('1/4 de final')) {
          return descriptor.replace('1/4 de final', 'Quarterfinals')
        }
        else if (descriptor.includes('Semifinales')) {
          return descriptor.replace('Semifinales', 'Semifinals')
        }
        else {
          return descriptor
        }
      }
      return descriptor
    },

    goNavDay: function (round) {
      this.$parent.goNavDay(round);
    },
    isActualRound: function (round) {
      return this.$props.actual.round.id == round.id;
    },
    isActualCompRound: function (round) {
      return this.$store.state.general.actualCompRound == round.id;
    }
  },
  mounted() {
    var selectedItem = document.querySelector(".nav-opt--sel");
    if (selectedItem) {
      selectedItem.scrollIntoView({ block: "center" });
    }
  }
};
</script>
