<template lang="pug">

.nav-cont.nav-cont--day(ref="navContainer" @scroll="isWidget ? onScroll() : null")

  router-link.nav-cont__calendar(
    v-if="(!$store.state.general.isApp && !isWidget)"
    :to="{name: $store.state.general.lang+'.home'}"
  )
    span {{ $t('Go to calendar') }}
    i: font-awesome-icon(icon="th")

  .nav-day(v-if="getDayList().length" v-for="day of getDayList()")
    span.nav-day__date {{day.verbose}}
    .nav-day-match-wrap
      .nav-day-match(
        v-for="match in match_list" 
        :key="match.id"
        :id="match.live ? 'live-'+match.id : match.id"
        ref="navDayMatch"
        v-if="isToday((match.date+match.time),day.datetime)"

        :class="{'nav-day-match--sel': isActualMatch(match)}"
        )
          .ge-match.ge-match--nav(
            role="button" 
            @click.left="isWidget ? selectNewTabMatch(match) : selectMatch(match)"
            @click.middle="selectNewTabMatch(match)"
          )
            .ge-match-time
              .ge-match-time-info(v-if="match.finalized")
                span {{ $t('Finished') }}
              .ge-match-time-info(v-else-if="match.live && match.period == 3 && match.crono == 600")
                .ge-match-time-info__live
                span {{ $t('Half-time') }}
              .ge-match-time-info(v-else-if="match.live")
                .ge-match-time-info__live
                span(v-if="(match.period > 4)") {{ $t('OT') }} {{match.period - 4}}
                span(v-else) {{match.period}}{{ $t('Q') }}
              .ge-match-time-info(v-else-if="isRoasterReady(match)")
                span {{ parsedTime(match.time,match.date) }}
            .ge-match-info-wrap
              .ge-match-info
                .ge-match-info__name 
                  span {{ match.local_team.team_abbrev_name }}
                .ge-match-info-shield
                  img(:src="match.local_image", :alt="match.local_team.team_abbrev_name")
                .ge-match-info__score(v-if="match.live || match.finalized || isRoasterReady(match)")
                  AnimatedValue(:value="isRoasterReady(match) ? '-' : match.score_local")
              .ge-match-info__hour(v-if="!(match.live || match.finalized || isRoasterReady(match))")
                span {{ parsedTime(match.time,match.date) }}
              .ge-match-info
                .ge-match-info__score(v-if="match.live || match.finalized || isRoasterReady(match)")
                  AnimatedValue(:value="isRoasterReady(match) ? '-' : match.score_visitor")
                .ge-match-info-shield
                  img(:src="match.visitor_image", :alt="match.visitor_team.team_abbrev_name")
                .ge-match-info__name 
                  span {{ match.visitor_team.team_abbrev_name }}
            span.ge-match__round(v-if="selectorRoundWeek == 'week'" v-text="parsedRound(match)")
    //-
      .nav-day-sponsor
        img(src="", alt="")
  .nav-day(v-else)
    span {{ $t('Not defined') }}


  i.nav-cont-arrow.nav-cont-arrow--left(role="button" @click="scrolltoLeft" v-if="isWidget && showLeftArrow")
    font-awesome-icon(icon="chevron-left")
  i.nav-cont-arrow.nav-cont-arrow--right(role="button" @click="scrolltoRight" v-if="isWidget && showRightArrow")
    font-awesome-icon(icon="chevron-right")

		
</template>

<script>

import AnimatedValue from "@/components/subcomponents/general/AnimatedValue.vue"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faChevronLeft, faChevronRight)

export default {
  name: "NavDay",
  props: {
    match_list: Array,
    isWidget: Boolean
  },
  data() {
    return {
      isFirstTime: true,
      navContainerWidth: 0,
      navContainerScrollLeft: 0,
      navContentWidth: 0
    }
  },
  computed: {
    showLeftArrow() {
      return this.navContainerScrollLeft > 0;
    },
    showRightArrow() {
      if (this.navContainerScrollLeft == 0) {
        return true
      }
      return ((this.navContainerScrollLeft + this.navContainerWidth) < this.navContentWidth)
    },
    selectorRoundWeek() {
      return this.$store.state.general.selectorRoundWeek
    },
    root_element() {
      if (this.$store.state.general.isWidget) {
        if (document.querySelector('widget-results')) {
          return document.querySelector('widget-results').shadowRoot
        }
      }
      return document
    }
  },
  methods: {
    onWheel: function (e) {
      var navContainerEl = this.root_element.querySelector('.nav-cont');
      navContainerEl.scrollLeft += (e.deltaY)
      e.preventDefault();
    },
    onScroll() {
      this.navContainerScrollLeft = this.$refs.navContainer.scrollLeft;
      this.navContentWidth = this.$refs.navContainer.scrollWidth
    },
    handleWindowResize() {
      var navContainerEl = this.root_element.querySelector('.nav-cont');
      this.navContainerWidth = navContainerEl.clientWidth;
    },


    scrolltoLeft() {
      let navContainerEl = this.root_element.querySelector('.nav-cont');
      navContainerEl.scrollBy({
        top: 0,
        left: -navContainerEl.clientWidth + 100,
        behavior: 'smooth'
      });
    },
    scrolltoRight() {
      let navContainerEl = this.root_element.querySelector('.nav-cont');
      navContainerEl.scrollBy({
        top: 0,
        left: navContainerEl.clientWidth - 100,
        behavior: 'smooth'
      });
    },
    parsedRound(match) {
      const descriptor = match.matchweek_descriptor
      const number = match.matchweek_number
      if (descriptor) {
        if (descriptor.includes('Jornada')) {
          return `${this.$i18n.t("Round")} ${number}`
        }
        if (this.$store.state.general.lang == 'en') {
          if (descriptor.includes('1/8 de final')) {
            return descriptor.replace('1/8 de final', 'Last 16')
          }
          if (descriptor.includes('1/4 de final')) {
            return descriptor.replace('1/4 de final', 'Quarterfinals')
          }
          if (descriptor.includes('Cuartos de final')) {
            return descriptor.replace('Cuartos de final', 'Quarterfinals')
          }
          if (descriptor.includes('Semifinales')) {
            return descriptor.replace('Semifinales', 'Semifinals')
          }
        }
      }
      return descriptor
    },
    isRoasterReady(match) {
      return match.selected_players
    },
    scrollToCurrentMatch() {
      var container = document.getElementsByClassName('nav-cont-wrap')[0]
      let selectedItem = document.querySelector(".nav-day-match--sel")
      if (!selectedItem) {
        selectedItem = document.querySelector('[id^="live-"]')
      }
      if (selectedItem) {
        let allMatches = document.getElementsByClassName('nav-day-match')
        let index = [].indexOf.call(allMatches, selectedItem)
        let position = selectedItem.offsetTop - 100
        if (index) {
          selectedItem = allMatches[index - 1]
          position = selectedItem.offsetTop + selectedItem.clientHeight / 2
        }
        this.$nextTick(() => {
          container.scroll(
            {
              top: position,
              behavior: "smooth"
            }
          )
        })
      }
    },
    getMatchToScroll() {
      let match_list = this.$props.match_list
      if (match_list && this.$refs.navDayMatch) {
        let live_matches = match_list.filter(match => match.live == true)
        let finished_matches = match_list.filter(match => match.finalized == true)

        let go_to_match = ""
        if (live_matches.length) {
          go_to_match = live_matches[0]
        } else {
          if (finished_matches.length != match_list.length) {
            go_to_match = finished_matches[finished_matches.length - 1]
          } else {
            go_to_match = match_list[0]
          }
        }
        return this.$refs.navDayMatch.find(
          item => item.id === go_to_match.id.toString()
        )
      }
      return ""
    },
    widgetScrollToCurrentMatch() {
      let navContainerEl = this.$parent.root_element.querySelector('.nav-cont');
      let matchWrapEl = this.getMatchToScroll()
      if (matchWrapEl) {
        let matchEl = matchWrapEl.querySelector(".ge-match")
        let matchOffset = matchWrapEl.parentElement.parentElement.offsetLeft + matchEl.offsetLeft - 10
        navContainerEl.scrollBy({
          top: 0,
          left: matchOffset,
          behavior: 'smooth'
        });
      } else {
        navContainerEl.scrollBy({
          top: 0,
          left: 0,
        });
      }
    },
    isToday(match_date, day_date) {
      if (!match_date && !day_date) {
        return true
      }
      if (day_date) {
        if (new Date(match_date * 1000).getDate() == day_date.getDate() && new Date(match_date * 1000).getMonth() == day_date.getMonth()) {
          return true
        }
      }
      return false
    },
    getDayList() {
      let days = [];
      let day_list = [];
      for (let match of this.$props.match_list) {
        if (match.date && match.time) {
          let match_date = new Date((match.date + match.time) * 1000)
          let day = `${match_date.getDate()}-${match_date.getMonth()}`
          if (days.indexOf(day) < 0) {
            day_list.push(
              {
                datetime: match_date,
                verbose: `${this.getDay(match_date.getDay())} ${match_date.getDate()} ${this.$i18n.t('of')} ${this.getMonth(match_date.getMonth())}`
              }
            )
          }
          days.push(day)
        } else if (match.date) {
          let match_date = new Date((match.date) * 1000)
          let day = `${match_date.getDate()}-${match_date.getMonth()}`
          if (days.indexOf(day) < 0) {
            day_list.push(
              {
                datetime: match_date,
                verbose: `${this.getDay(match_date.getDay())} ${match_date.getDate()} ${this.$i18n.t('of')} ${this.getMonth(match_date.getMonth())}`
              }
            )
          }
          days.push(day)
        } else {
          let match_date = this.$i18n.t('Date to be determined')
          if (days.indexOf(match_date) < 0) {
            day_list.push(
              {
                datetime: match.date,
                verbose: match_date
              }
            )
          }
          days.push(match_date)
        }
      }
      return day_list
    },
    getDay(day) {
      return this.$i18n.t(
        [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ][day]
      );
    },
    getMonth(month) {
      return this.$i18n.t(
        [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ][month]
      );
    },
    selectMatch(match) {
      if (this.$route) {
        if (match.id != this.$route.params.id_match) {
          this.$store.commit("general/setError", false);
          this.$store.commit("match/setInitialMatchData", match);
          // this.$store.commit("general/setNav", 0);
          let routeName = (match.live || match.finalized || match.selected_players) ? this.$route.name : this.$store.state.general.lang + '.prematch'
          this.$router.push({
            name: routeName,
            params: { id_match: match.id, goTop: true }
          });
        }
      }
      this.$store.commit("general/setNav", 0);
    },
    selectNewTabMatch(match) {
      if (this.$route) {
        let routeName = (match.live || match.finalized || match.selected_players) ? this.$route.name : this.$store.state.general.lang + '.prematch'
        let routeData = this.$router.resolve({ name: routeName, params: { id_match: match.id } });
        window.open(routeData.href, '_blank');
      } else {
        window.open('https://jv.acb.com/es/' + match.id, '_blank');
      }
    },
    isActualMatch(match) {
      if (this.$route) {
        return this.$route.params.id_match == match.id;
      }
    },
    parsedTime(time, date) {
      if (!time) {
        return this.$i18n.t('TBD')
      }
      let d = new Date((date + time) * 1000);
      return d.toTimeString().substr(0, 5);
      // return d.toUTCString().slice(17,22);
    }
  },
  components: {
    AnimatedValue,
    FontAwesomeIcon
  },
  watch: {
    match_list() {
      if (this.$data.isFirstTime) {
        this.$data.isFirstTime = false
        this.$nextTick(() => this.$props.isWidget ? this.widgetScrollToCurrentMatch() : this.scrollToCurrentMatch())
      }
    },
  },
  mounted() {
    this.$nextTick(() => this.$props.isWidget ? this.widgetScrollToCurrentMatch() : this.scrollToCurrentMatch())
    if (this.$props.isWidget) {
      this.navContainerWidth = this.$refs.navContainer.clientWidth;
      this.navContainerScrollLeft = this.$refs.navContainer.scrollLeft;
      this.navContentWidth = this.$refs.navContainer.scrollWidth;
      window.addEventListener('resize', this.handleWindowResize);
    }
  }
}
</script>
