<template lang="pug">

.lo
    img(src="~public/loading.png")
    
            
</template>

<style lang="sass">
@keyframes spin
    from
        transform: rotate(0deg)
    to
        transform: rotate(360deg)
.lo
    display: flex
    justify-content: center
    padding-top: 5em
    & > img
        animation-name: spin
        animation-duration: 2000ms
        animation-iteration-count: infinite
        animation-timing-function: linear
        max-width: 64px
        max-height: 64px
        
</style>

<script>

export default {
    name: "Loading"
}

</script>