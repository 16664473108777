import { getAPI, parsedQueryString } from "@/api/config";

// import store from "@/store/index.js";
import { getUrlResizedRatio } from "@/api/adjust_mediacenter_url";

const getLastMatches = async (params, idTeam = null) => {
  const apiResponse = await getAPI(
    "/Matches/lastmatches" + parsedQueryString(params)
  );

  if (!idTeam) {
    return parsedVersusMatches(apiResponse);
  } else {
    return parsedMatchesStreak(apiResponse, idTeam);
  }
};

const getPriorMatches = async (params) => {
  const apiResponse = await getAPI(
    "/Statistics/club/precedents" + parsedQueryString(params),
    "openapi"
  );

  return parsedPriorMatches(apiResponse);
};

export default {
  getLastMatches,
  getPriorMatches,
};
const parsedMatchesStreak = (data, idTeam1) => {
  if (data.length) {
    data = data.filter((match) => {
      let teamScore;
      let opponentScore;
      let teamFound = false;

      if (match.id_team_local == idTeam1) {
        teamScore = match.score_local;
        opponentScore = match.score_visitor;
        match.is_local = true;
        teamFound = true;
      } else if (match.id_team_visitor == idTeam1) {
        teamScore = match.score_visitor;
        opponentScore = match.score_local;
        match.is_local = false;
        teamFound = true;
      }

      if (teamFound) {
        if (teamScore > opponentScore) {
          match.result = "V";
        } else {
          match.result = "L";
        }
        if (idTeam1 != null) {
          const opponent_team_media =
            match.id_team_local === idTeam1
              ? match.visitor_team.media
              : match.local_team.media;
          const opponentLogo = opponent_team_media.find(
            (img) => img?.type === "logo"
          );
          if (opponentLogo) {
            match.opponent_image = getUrlResizedRatio(
              opponentLogo,
              opponentLogo.id_type
            );
          }
        }
      }
      return teamFound;
    });
  }
  return data;
};

const parsedVersusMatches = (data) => {
  if (data) {
    for (let match of data) {
      const localLogo = match.local_team.media.find(
        (img) => img && img.type === "logo"
      );
      if (localLogo == undefined) {
        match.local_image = "/local-team-no-logo.png";
      } else {
        match.local_image = getUrlResizedRatio(localLogo, localLogo.id_type);
      }
      const visitorLogo = match.visitor_team.media.find(
        (img) => img && img.type === "logo"
      );
      if (visitorLogo == undefined) {
        match.visitor_image = "/visitor-team-no-logo.png";
      } else {
        match.visitor_image = getUrlResizedRatio(
          visitorLogo,
          visitorLogo.id_type
        );
      }
    }
  }

  return data;
};

const parsedPriorMatches = (data) => {
  if (data) {
    let parsedData = {
      club1_victories: data.club1_victories,
      club2_victories: data.club2_victories,
      matches: [],
    };
    for (let match of data.match_list.slice(0, 5)) {
      const localLogo = match.local_team.media.find(
        (img) => img && img.type === "logo"
      );
      if (localLogo == undefined) {
        match.local_image = "/local-team-no-logo.png";
      } else {
        match.local_image = getUrlResizedRatio(localLogo, localLogo.id_type);
      }
      const visitorLogo = match.visitor_team.media.find(
        (img) => img && img.type === "logo"
      );
      if (visitorLogo == undefined) {
        match.visitor_image = "/visitor-team-no-logo.png";
      } else {
        match.visitor_image = getUrlResizedRatio(
          visitorLogo,
          visitorLogo.id_type
        );
      }
      parsedData.matches.push(match);
    }

    return parsedData;
  }

  return data;
};
