import Vue from "vue"
import Vuex from "vuex"

import match from "@/store/modules/match.store.js"
import general from "@/store/modules/general.store.js"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    match,
    general
  }
})