<template lang="pug">

transition(name="fade-value" mode="out-in")
    span(v-if="change") {{value}}
            
</template>

<style lang="sass">

.fade-value-enter-active, .fade-value-leave-active
    transition: 0.5s ease

.fade-value-enter, .fade-value-leave-to
    opacity: 0

</style>

<script>

export default {
    name: "AnimatedValue",
    props: ['value'],
    data() {
        return {
            change: true
        }
    },
    methods: {
        add() {
            this.$props.value += 1
        }
    },
    watch: {
        value(newVal,oldVal) {
            if (newVal != oldVal) {
                this.change = false
            }
            setTimeout(() => {this.change=true},500)
        }
    }
}

</script>