<template lang="pug">

.nav-cont
    .nav-opt(
        v-for="year in year_list"
        :key="year"
        v-if="year>=oldestACBLeagueYear"
        @click="goNavCompetition(year)"
        :class="{'nav-opt--sel': isActualYear(year)}"
        )
        span {{year}}-{{year+1}}
        i
            font-awesome-icon(icon="chevron-right")
            
</template>

<script>
export default {
  name: "NavYear",
  props: {
    year_list: Array,
    actual: Object
  },
  computed: {
    oldestACBLeagueYear() {
			return this.$store.state.general.oldestACBLeagueYear
		},
  },
  methods: {
    goNavCompetition: function(year) {
      this.$parent.goNavCompetition(year);
    },
    isActualYear: function(year) {
      return this.$props.actual.year == year;
    }
  },
  // created() {
    // this.$parent.updateYearList();
  // },
  mounted() {
    var selectedItem = document.querySelector(".nav-opt--sel");
    if (selectedItem) {
      selectedItem.scrollIntoView({ block: "center" });
    }
  }
};
</script>
