<template lang="pug">
.nav-top-wrap: .nav-top
    .nav-top-pri(v-if="!this.isWidget")
        .nav-top-pri-opt(@click="nextNavState()" role="button")
            i.nav-top-pri-opt__back(v-if="(this.$parent.nav_state < 4) && (this.$parent.nav_state > 1)")
                font-awesome-icon(icon="chevron-left")

            svg.nav-top-pri-opt__menu(v-if="(this.$parent.nav_state == 1)" height='15' viewbox='0 0 5 15' fill='none' xmlns='http://www.w3.org/2000/svg')
                rect(width='8' height='2' fill='#D9D9D9')
                rect(y='5' width='8' height='2' fill='#D9D9D9')
                rect(y='10' width='8' height='2' fill='#D9D9D9')

            img.nav-top-pri-opt__comp(
                v-if="this.$parent.nav_state<3 && getCompetitionImage"
                :src="getCompetitionImage"
                :alt="this.$parent.competitionName"
                )
            span(v-if="(this.$parent.nav_state < 4) && this.$parent.actual.year").nav-top-pri-opt__year {{actualYearSliced(this.$parent.actual.year)}} - {{actualYearSliced(this.$parent.actual.year+1)}}
            span(v-if="this.$parent.nav_state == 4").nav-top-pri-opt__year.nav-top-pri-opt__year--label {{ $t('Seasons') }}
        
        //- If not acbapp show
        //- router-link.nav-top-pri__calendar(v-if="!$store.state.general.isApp" :to="{name: $store.state.general.lang+'.home'}")
        //- 	i: font-awesome-icon(icon="th")
        //- else
        .nav-top-pri__stand(
            v-if="((this.$parent.nav_state <= 2) && this.$parent.standings.length && !$store.state.general.isApp)"
            @click="toggleStandings()"
            role="button"
            :class="[{'nav-top-pri__stand--show': this.$parent.show_standings}]"
        )
            |{{ $t('Standings') }}
            i 
                font-awesome-icon(icon="list-ol")
    NavSlider(
        :week_list = "this.$parent.nav.week_list"
        :round_list = "this.$parent.nav.round_list"
        :actual = "this.$parent.actual"
        :selectorRoundWeek ="selectorRoundWeek"
        :active_element= "this.$parent.nav.active_element"
        v-if="(this.$parent.nav_state == 1)"
    )

    .nav-top-sec(v-if="this.$parent.nav_state == 2")
        .nav-top-sec-round_week(v-if="!this.$parent.show_standings")
            .ge-btn(
                role="button"
                :class="(selectorRoundWeek == 'round') ? 'ge-btn--gradient' : 'ge-btn--gray'"
                @click="selectRounds()"
            ) {{ $t(getJornadaorRonda) }}
            .ge-btn(
                role="button"
                :class="(selectorRoundWeek == 'week') ? 'ge-btn--gradient' : 'ge-btn--gray'"
                @click="selectWeeks()"
            ) {{ $t('Week') }}

        .nav-top-sec__close_stan(role="button" v-if="this.$parent.show_standings" @click="toggleStandings()")
            i: font-awesome-icon(icon="times")
            span {{ $t('Close standings') }}


</template>

<style lang="sass">
    
</style>

<script>

import NavSlider from "@/components/subcomponents/nav/NavSlider.vue"


export default {
    name: "NavTop",
	props: {
		isWidget: Boolean
	},
    data() {
		return {
			
		}
	},
	computed: {
		getCompetitionImage() {
			return this.$parent.getCompetitionImage
		},
		nextNavState() {
			return this.$parent.nextNavState
		},
		selectWeeks(){
			return this.$parent.selectWeeks
		},
		selectRounds(){
			return this.$parent.selectRounds
		},
		selectorRoundWeek() {
			return this.$store.state.general.selectorRoundWeek
		},
		getJornadaorRonda(){

			if (this.$parent.competitionName) {
				if (this.$parent.competitionName.includes("Liga")){
					return "Jornada"
				} else {
					return "Ronda"
				}
			} else {
				return ""
			}
		},

		toggleStandings(){
			return this.$parent.toggleStandings
		}

		
	},
	methods:{
		actualYearSliced(year){
			var sliced_year = String(year)
			return sliced_year.substr(sliced_year.length - 2)
		},
	},

    components: {
        NavSlider,
    }
}

</script>