<template lang="pug">
Nav(isWidget = true)
</template>

<style lang="sass">

@import "@/styles/general.sass"
@import "@/styles/basecamp.sass"
@import "@/styles/buttons.sass"
@import "@/styles/table.sass"

.nav
    //- from html tag
    font-family: $header-font-family //$global-font-family
    font-size: $global-font-size
    line-height: $global-font-height
    scroll-behavior: smooth
    color: $black
    background-color: #ECECEC

    //- from body tag
    //-overflow-y: scroll
    overflow-x: none
    margin: 0
    &[live]
        overflow: hidden

</style>

<script>
import store from "@/store/index.js";
import i18n from "@/i18n.js";
import Nav from "@/components/Nav.vue"

import { dom } from '@fortawesome/fontawesome-svg-core'

export default {
  store,
  i18n,

  props: {
    name: String,
  },

  components: {
    Nav,
  },
  mounted () {
    if (document.querySelector('widget-results')) {
      const shadowRoot = document.querySelector('widget-results').shadowRoot
      const id = 'fa-styles'

      if (!shadowRoot.getElementById(`${id}`)) {
        const faStyles = document.createElement('style')
        faStyles.setAttribute('id', id)
        faStyles.textContent = dom.css()
        shadowRoot.appendChild(faStyles)
      }
    }
  }
};
</script>