import api from "@/api/index"
import store from "@/store/index.js"

const state = {
    id: null,
    idCompetition: null,
    idEdition: null,
    idRound: null,
    idWeek: null,
    selQuarter: 0,
    quarterNum: 0,
    selTeam: 'local',
    localShortName: null,
    localAbbrev: null,
    visitorShortName: null,
    visitorAbbrev: null,
    localImage: null,
    visitorImage: null,
    localId: null,
    visitingId: null,
    localIdClub: null,
    visitingIdClub: null,
    localNameSafeLength: null,
    visitorNameSafeLength: null,

    highlights: 0,
    status: 'finished',
    crowd: null,
    arena: null,
    start_date: null
}

const actions = {
    async loadMatchData({ commit }, match_id) {
        let match = await api.match.getMatchInfo(match_id)
        if (match) {
            commit('setId', match.id)
            commit('setIdCompetition', match.id_competition)
            commit('setIdEdition', match.id_edition)
            commit('setIdRound', match.id_round)
            commit('setIdWeek', match.id_week)
            commit('setQuarter', 0)
            commit('setTeam', 'local')
            commit('setLocalShortName', match.team1.team_actual_short_name)
            commit('setVisitorShortName', match.team2.team_actual_short_name)
            commit('setLocalAbbrev', match.team1.team_abbrev_name)
            commit('setVisitorAbbrev', match.team2.team_abbrev_name)
            commit('setLocalImage', match.local_image)
            commit('setLocalId', match.team1.id)
            commit('setLocalIdClub', match.team1.id_club)
            commit('setVisitorImage', match.visitor_image)
            commit('setVisitingId', match.team2.id)
            commit('setVisitingIdClub', match.team2.id_club)
            commit('setStatus', match)
            commit('setCrowd', match.crowd)
            commit('setArena', match.arena.name)
            commit('setMatchDate', match.start_date)
            commit('setLocalNameSafeLength', match)
            commit('setVisitorNameSafeLength', match)
        }
        // else {
        store.commit('general/setContentReady', true)
        // }
    }
}

const mutations = {
    setMatchDate(state, value) {
        state.start_date = value
    },
    setCrowd(state, value) {
        state.crowd = value
    },
    setArena(state, value) {
        state.arena = value
    },
    setLocalAbbrev(state, name) {
        state.localAbbrev = name
    },
    setVisitorAbbrev(state, name) {
        state.visitorAbbrev = name
    },
    setId(state, id) {
        state.id = id
    },
    setIdCompetition(state, id) {
        state.idCompetition = id
    },
    setIdEdition(state, id) {
        state.idEdition = id
    },
    setIdRound(state, id) {
        state.idRound = id
    },
    setIdWeek(state, id) {
        state.idWeek = id
    },
    setQuarter(state, q) {
        state.selQuarter = q
    },
    setTeam(state, team) {
        state.selTeam = team
    },
    setLocalShortName(state, name) {
        state.localShortName = name
    },
    setVisitorShortName(state, name) {
        state.visitorShortName = name
    },
    setLocalImage(state, img) {
        state.localImage = img
    },
    setVisitorImage(state, img) {
        state.visitorImage = img
    },
    setLocalId(state, id) {
        state.localId = id
    },
    setLocalIdClub(state, id) {
        state.localIdClub = id
    },
    setVisitingId(state, id) {
        state.visitingId = id
    },
    setVisitingIdClub(state, id) {
        state.visitingIdClub = id
    },
    setQuarterNum(state, q) {
        state.quarterNum = q
    },
    async setStatus(state, match) {
        if (match.status == 'FINALIZED') {
            state.status = 'finished'
        } else if (match.status == 'SELECTED_PLAYERS') {
            state.status = 'roaster-ready'
        } else if (match.status == 'NOT_STARTED') {
            state.status = 'upcoming'
        } else {
            state.status = 'playing'
        }
        store.commit('general/setContentReady', true)
    },
    setHighlights(state, val) {
        state.highlights = val
    },
    setInitialMatchData(state, match) {
        if (!match.finalized && !match.live) {
            if (match.selected_players) {
                state.status = 'roaster-ready'
            } else {
                state.status = 'upcoming'
            }
        } else if (match.live) {
            state.status == 'playing'
        } else {
            state.status = 'finished'
        }
        state.arena = match.arena.name
    },
    setLocalNameSafeLength(state, match) {
        state.localNameSafeLength = match.team1.team_actual_name.length < 20 ? match.team1.team_actual_name : match.team1.team_actual_short_name;
    },
    setVisitorNameSafeLength(state, match) {
        state.visitorNameSafeLength = match.team2.team_actual_name.length < 20 ? match.team2.team_actual_name : match.team2.team_actual_short_name;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}