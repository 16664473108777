<template lang="pug">

.nav-cont
    .nav-opt(
        v-if="week_list.length"
        role="button" 
        v-for="week in week_list"
        :key="week.id" @click="goNavDay(week)"
        :class="{'nav-opt--current': isActualCompWeek(week), 'nav-opt--sel': isActualWeek(week)}"
        )
        span {{ $t('Week ')}} {{ parseWeekDate(week.start_date) }}
        //- span {{ week.description }}
        i
            font-awesome-icon(icon="chevron-right")
    .nav-opt(v-else)
        span {{ $t('Not defined')}}
</template>

<script>
export default {
  name: "NavWeek",
  props: {
    week_list: Array,
    actual: Object
  },
  methods: {
    goNavDay: function(week) {
      this.$parent.goNavDay(week);
    },
    isActualWeek: function(week) {
      return this.$props.actual.week.id == week.id;
    },
    isActualCompWeek: function(week) {
      return this.$store.state.general.actualCompWeek == week.id;
    },
    parseWeekDate(date) {
      let datetime = new Date(date*1000)
      return `${datetime.getDate()}/${datetime.getMonth()+1}`
    }
  },
  mounted() {
    var selectedItem = document.querySelector(".nav-opt--sel");
    if (selectedItem) {
      selectedItem.scrollIntoView({ block: "center" });
    }
  }
};
</script>
