<template lang="pug">
.nav-top-sec 
    i.nav-top-sec-arrow.nav-top-sec-arrow--left(role="button" @click="scrolltoLeft")
        font-awesome-icon(icon="chevron-left")
    i.nav-top-sec-arrow.nav-top-sec-arrow--right(role="button" @click="scrolltoRight")
        font-awesome-icon(icon="chevron-right")

    .nav-top-sec-swipe(ref="swipe_cont" @mousewheel="scrollX")
        //- Navigation Weeks
        .nav-top-sec-swipe-empty_item
        .nav-top-sec-swipe-item(
            v-if="selectorRoundWeek === 'week' && week_list.length"
            role="button" 
            v-for="week in week_list"
                :key="week.id" @click="goNavDay(week)"
                :class="{'nav-top-sec-swipe-item--current': isActualCompWeek(week), 'nav-top-sec-swipe-item--selected': isActualWeek(week)}"
            )
            span.nav-top-sec-swipe-item__title(v-if="isActualWeek(week)") {{ $t('Week')}} 
            span.nav-top-sec-swipe-item__value {{ parseWeekDate(week.start_date) }}            
        
        //- Navigation Rounds
        .nav-top-sec-swipe-item(
            v-if="selectorRoundWeek === 'round'"
            role="button" 
            v-for="round in round_list"
                :key="round.id" @click="goNavDay(round)"
                :class="{'nav-top-sec-swipe-item--current': isActualCompRound(round), 'nav-top-sec-swipe-item--selected': isActualRound(round)}"
            )
            span.nav-top-sec-swipe-item__title(v-if="showDescription(round)") {{ $t('Round')}}
            span.nav-top-sec-swipe-item__value(:class="{'nav-top-sec-swipe-item__value--small': parseRound(round).length > 5}") {{ parseRound(round) }}               
        .nav-top-sec-swipe(v-if="(selectorRoundWeek === 'week' && !week_list.length) || (selectorRoundWeek === 'round' && !round_list.length)")
            span {{ $t('Not defined')}}
        .nav-top-sec-swipe-empty_item

</template>
    
<script>

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add( faChevronLeft, faChevronRight )

export default {
    name: "NavSlider",
    props: {
        round_list: Array,
        week_list: Array,
        actual: Object,
        selectorRoundWeek: String,
        active_element: Boolean
    },
    
    updated() {
        if (this.root_element.querySelector(".nav-top-sec-swipe-item--selected") && this.active_element === false){
            this.scrolltoActiveElement(".nav-top-sec-swipe-item--selected", "auto")
            this.$parent.$parent.setActiveElementTrue()
        }
    },  
    async mounted() {
        await this.$nextTick()
        if (this.root_element.querySelector(".nav-top-sec-swipe-item--selected") && this.active_element === false){
            this.scrolltoActiveElement(".nav-top-sec-swipe-item--selected", "auto")
            this.$parent.$parent.setActiveElementTrue()
        }

    },
    components: {
        FontAwesomeIcon
    },
    computed: {
        root_element() {
			if (this.$store.state.general.isWidget) {
				if (document.querySelector('widget-results')) {
					return document.querySelector('widget-results').shadowRoot
				}
			}
            return document
        }
    },
    methods: {
        scrollX(e) {
            this.scrolltoRight(e, e.deltaY)
        },

        showDescription(round) {
            if (this.isActualRound(round)){
                if (round.descriptor.includes("Jornada") || round.descriptor.includes("Semana")){
                    return true
                } 
            }
            return false
        },

        parseRound(round) {
            const descriptor = round.descriptor
            const number = round.num_matchweek
            if (descriptor.includes('Jornada')) {
                return number
            } 
            if (this.$store.state.general.lang == 'en') {
                if (descriptor.includes('1/8 de final')) {
                    return descriptor.replace('1/8 de final', 'Last 16')
                }
                if (descriptor.includes('1/4 de final')) {
                    return descriptor.replace('1/4 de final', 'Quarterfinals')
                }
                if (descriptor.includes('Semifinales')) {
                    return descriptor.replace('Semifinales', 'Semifinals')
                }
                return descriptor
            } else {
                return descriptor
            }
        },
        async goNavDay(round) {
            await this.$parent.$parent.goNavDay(round, false)
            if (this.root_element.querySelector(".nav-top-sec-swipe-item--selected")){
                this.scrolltoActiveElement()
            }
        },

        parseWeekDate(date) {
            let datetime = new Date(date*1000)
            return `${datetime.getDate()}/${datetime.getMonth()+1}`
        },

        isActualWeek(week) {
            if(this.actual) {
                return this.actual.week.id == week.id;
            }
        },

        isActualCompWeek(week) {
            return this.$store.state.general.actualCompWeek == week.id;
        },

        isActualCompRound(round) {
            return this.$store.state.general.actualCompRound == round.id;
        },

        isActualRound(round) {
            if(this.actual && round) {
                return this.actual.round.id == round.id;
            }
        },
        scrolltoActiveElement(element_name = ".nav-top-sec-swipe-item--selected", smooth = "smooth") {
            const element = this.root_element.querySelector(element_name)
            const parent = this.root_element.querySelector(".nav-top-sec-swipe") 
            var position = element.offsetLeft - (parent.getBoundingClientRect().width - element.getBoundingClientRect().width)/2;
            parent.scrollTo({ top: 0, left: position, behavior: smooth })
        },
        
        async setActive() { 
            if (this.root_element.querySelector(".nav-top-sec-swipe-item--selected")) {
                this.scrolltoActiveElement()
            }

        },
        scrolltoLeft(e, left = -100){
            var positions = this.root_element.querySelector('.nav-top-sec-swipe');
            positions.scrollBy({
                top: 0,
                left: left,
                behavior: 'smooth'
            });
        },
        scrolltoRight(e, left = +100){
            var positions = this.root_element.querySelector('.nav-top-sec-swipe');
            positions.scrollBy({
                top: 0,
                left: left,
                behavior: 'smooth'
            });
        },    
    },
};
</script>

<style lang="sass"> 
.nav-top-sec
    display: grid
    position: relative
    justify-items: center
    align-items: center
    &:after
        position: absolute
        top: 0
        right: 0
        left: 0
        bottom: 0
        content:""
        background: linear-gradient(90deg, rgba(37,37,37,1) 2%, rgba(37,37,37,0) 20%, rgba(37,37,37,0) 80%, rgba(37,37,37,1) 98%)
        z-index: 2
        pointer-events: none
    &:hover
        &:after
            background: linear-gradient(90deg, rgba(37,37,37,1) 7%, rgba(37,37,37,0) 20%, rgba(37,37,37,0) 80%, rgba(37,37,37,1) 93%)              
    &-cont
        position: relative
    &-swipe
        overflow: auto
        display: flex
        flex-wrap: nowrap
        height: 100%
        margin: 0
        width: 100%
        scrollbar-width: none
        &::-webkit-scrollbar 
            display: none
        &-empty_item
            padding-left: 50%

        &-item
            // display: flex
            // flex-direction: column
            padding: 0 0.8em 0.4em

            // align-items: center
            // align-self: self-end
            display: grid
            grid-template-rows: 1fr auto
            justify-items: center
            cursor: pointer
            color: darken($white, 30)
            align-items: end

            @media (hover: hover) and (pointer: fine)
                &:hover
                    color: $orange
            &__title
                height: 1.6em
                font-size: 0.8em
                visibility: hidden
                .nav-top-sec-swipe-item--selected &
                    visibility: visible 
            &__value
                line-height: 1.2em
                padding: 0 0.2em
                font-size: 1.3em
                white-space: nowrap
                &--small
                    font-size: 1.1em
            &--selected
                color: $white 
                font-weight: $bold
                background: linear-gradient(0deg, lighten($black, 10) 50%, $black 100%)
                &:hover
                    color: $white
                    cursor: auto
            &--current
                position: relative
                & .nav-top-sec-swipe-item__value::after
                    content: ""
                    width: 6px
                    height: 6px
                    border-radius: 6px
                    position: absolute
                    top: 1em
                    left: 50%
                    transform: translate(-50%, -50%)
                    background: darken($orange, 15)
                    z-index: -1
                &.nav-top-sec-swipe-item--selected
                    height: auto

                // & .nav-top-sec-swipe-item__value
                //     background: lighten($black, 10)
                //     padding: 0.1em 0.5em
                //     border-radius: 20px
            &--current.nav-top-sec-swipe-item--selected
                color: $white
                & .nav-top-sec-swipe-item__value::after
                    display: none
    &-arrow
        display: flex
        position: absolute
        cursor: pointer
        color: $white
        width: 27px
        height: calc(100% - 1em)
        justify-content: center
        align-items: center
        z-index: 10
        opacity: 0
        transition: .2s
        font-size: 1.2em
        bottom: 0
        @at-root .nav-top-sec:hover &
            opacity: 0
            transition: .2s
            +breakpoint(medium)
                opacity: 1
        @media (hover: hover) and (pointer: fine)
            &:hover
                color: $orange
        &--left
            left: 0
        &--right
            right: 0
             
</style>
