<template lang="pug">

table.ta(:class="pre_match ? 'ta--pm' : 'ta--st'")
	thead.ta-section.ta-section--head
		tr.ta-row
			th.ta-col.ta-col--pos {{ $t('Pos') }}
			th.ta-col.ta-col--img(v-if="!pre_match")
			th.ta-col.ta-col--team {{ $t('Team') }}
			th.ta-col {{ $t('P') }}
			th.ta-col {{ $t('W') }}
			th.ta-col {{ $t('L') }}
			th.ta-col(v-if="!pre_match") %
			th.ta-col {{ $t('Dif') }}
	tbody.ta-section.ta-section--body
		tr.ta-row(
			v-for="team of standings"
			:key="team.id"
			:class="{'ta-row--showing': isMatchSelected(team.id_team)}"
		)
			td.ta-col.ta-col--pos(:class="{'ta-col--pos--asc': team.on_playoff, 'ta-col--pos--des': team.on_descent}"): span {{ team.position }}
			td.ta-col.ta-col--img(v-if="!pre_match")
				img.ta-col__shield(:src="team.image") 
			td.ta-col.ta-col--team {{ team.team.team_actual_short_name }}
			td.ta-col {{ team.played }}
			td.ta-col {{ team.won }}
			td.ta-col {{ team.lost }}
			td.ta-col(v-if="!pre_match") {{ parsed_win_percentage(team.victories_percentage) }}
			td.ta-col {{ team.scoredFor - team.scoredAgainst }}

</template>


<style lang="sass">

</style>


<script>

export default {
	name: "StandingsTable",

	props: {
		standings: Array,
		pre_match: Boolean,
		sel_team: Number,
	},

	data() {
		return {
			showShortName: false,
		}
	},
	methods: {
		parsed_win_percentage(percentage) {
			let max_length = 4
			if (percentage < 10)
				max_length = 3
			return percentage.toString().substring(0, max_length)
		},
		isMatchSelected(id_team) {

			if (this.pre_match && this.sel_team) {
				if (this.sel_team == id_team) {
					return true
				}
				return false

			} else if (this.$route.name.includes('standings')) {
				return false
			} else {
				if (this.$store.state.match.localId == id_team || this.$store.state.match.visitingId == id_team) {
					return true
				}
				return false

			}
		},
		// check_standings_width() {
		// 	if (this.$refs.st.offsetWidth < 400) {
		// 		this.$data.showShortName = true
		// 	} else {
		// 		this.$data.showShortName = false
		// 	}
		// },
		// get_negative_image(team){
		// 	if (this.$route.name.includes('standings')) {
		// 		if (this.$store.state.general.isApp){
		// 			for(let media of team.team.media){
		// 				if(media.type == "logo_negativo"){
		// 					return media.url
		// 				}
		// 			}
		// 		}
		// 	}
		// 	return team.image
		// }
	},
	mounted() {
		// this.check_standings_width()
		// window.addEventListener('resize', () => { this.check_standings_width() })
	}
}

</script>