import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import obj from "svgo/lib/svgo/jsAPI"

// const BASE_URL_OPENAPILIVE = "https://api2-devel.acb.com/api/v1/openapilive";
// const BASE_URL_OPENAPI = "https://api2-devel.acb.com/api/v1/openapi";

// const BASE_URL_OPENAPILIVE = "https://api2.acb.com/api/v1/openapilive"
// const BASE_URL_OPENAPI = "https://api2.acb.com/api/v1/openapi"

const BASE_URL_OPENAPILIVE = process.env.VUE_APP_BASE_URL_OPENAPILIVE;
const BASE_URL_OPENAPI = process.env.VUE_APP_BASE_URL_OPENAPI;

const AUTH_TOKEN = process.env.VUE_APP_PUBLIC_TOKEN;

const mediacenter_types = [
  {
    id_type: 1,
    type: "foto_cuerpo",
    ratio: "5-6",
    size: "03",
  },
  {
    id_type: 2,
    type: "audio pronunciacion nombre",
    ratio: "",
    size: "03",
  },
  {
    id_type: 3,
    type: "foto_equipo",
    ratio: "",
    size: "01",
  },
  {
    id_type: 4,
    type: "foto_juego",
    ratio: "",
    size: "02",
  },
  {
    id_type: 5,
    type: "foto_de_cara",
    ratio: "5-6",
    size: "04",
  },
  {
    id_type: 6,
    type: "logo",
    ratio: "",
    size: "05",
  },
  {
    id_type: 7,
    type: "logo_negativo",
    ratio: "",
    size: "05",
  },
];
export { mediacenter_types };

Vue.use(VueAxios, axios);
axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;

const getAPI = async (url, api = "live") => {
  if (api == "live") {
    url = BASE_URL_OPENAPILIVE + url;
  } else {
    url = BASE_URL_OPENAPI + url;
  }
  let response = null,
    tries = 0;
  do {
    if (tries < 1) {
      try {
        response = await Vue.axios.get(url);
        break;
      } catch (err) {
        ++tries;
      }
    } else {
      break;
    }
  } while (!response);
  if (response) {
    return response.data;
  }
};

const parsedQueryString = (params) => {
  return "?" + new URLSearchParams(params).toString();
};

export {
  getAPI,
  parsedQueryString,
  BASE_URL_OPENAPI,
  BASE_URL_OPENAPILIVE,
  AUTH_TOKEN,
};
