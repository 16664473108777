<template lang="pug">

.nav-cont
    .nav-opt(
        role="button"
        v-for="competition in competition_list"
        :key="competition.id_competition"
        @click="(selectorRoundWeek == 'round') ? goNavRound(competition) : goNavWeek(competition)"
        :class="{'nav-opt--sel': isActualCompetition(competition)}"
        )
        //- img(v-if="competition.competition.url_image" :src="competition.competition.url_image" :alt="competition.competition.name")
        span {{competition.competition.official_name}}
        i
            font-awesome-icon(icon="chevron-right")  
</template>

<script>
export default {
  name: "NavCompetition",
  props: {
    competition_list: Array,
    actual: Object
  },
  computed: {
    selectorRoundWeek() {
      return this.$store.state.general.selectorRoundWeek
    }
  },
  methods: {
    goNavRound(competition) {
      this.$parent.goNavRound(competition);
    },
    // New
    goNavWeek(competition) {
      this.$parent.goNavWeek(competition);
    },
    isActualCompetition(competition) {
      return this.$props.actual.competition.id == competition.id_competition;
    }
  },
  created() {
    if (!this.$props.competition_list.length) {
      this.$parent.updateCompetitionList(this.$props.actual.year);
    }
  },
  mounted() {
    var selectedItem = document.querySelector(".nav-opt--sel");
    if (selectedItem) {
      selectedItem.scrollIntoView({ block: "center" });
    }
  }
};
</script>
