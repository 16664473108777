import { getAPI } from "@/api/config";
import store from "@/store/index.js";

import { getUrlResizedRatio } from "@/api/adjust_mediacenter_url";

const getActual = async () => {
  let actual = [];
  if (store.state.general.selectorRoundWeek == "round") {
    actual = parseActualRound(await getAPI("/Matchweeks/currentMatchweeks"));
  } else {
    actual = parseActualWeek(await getAPI("/Weeks/current"));
  }
  return actual;
};

const getActualFromMatch = async (idMatch) =>
  parseActualFromMatch(
    await getAPI("/Matches/matchlite" + `?idMatch=${idMatch}`)
  );

const getMatchList = async (idEdition, idCompetition, idMatchweek) =>
  parseMatchList(
    await getAPI(
      "/Matches/matchesbymatchweeklite" +
        `?idCompetition=${idCompetition}` +
        `&idEdition=${idEdition}` +
        `&idMatchweek=${idMatchweek}`
    )
  );

const getAllMatches = async (idEdition, idCompetition) =>
  parseMatchList(
    await getAPI(
      "/Matches/matchesbymatchweeklite" +
        `?idCompetition=${idCompetition}` +
        `&idEdition=${idEdition}`
    )
  );

const getMatchListByWeek = async (idEdition, idCompetition, idWeek) =>
  parseMatchList(
    await getAPI(
      "/Matches/matchesbyweeklite" +
        `?idCompetition=${idCompetition}` +
        `&idEdition=${idEdition}` +
        `&idWeek=${idWeek}`
    )
  );
const getMatchListByTeam = async (idEdition, idCompetition, idTeam) =>
  parseMatchList(
    await getAPI(
      "/Matches/matchesbymatchweeklite" +
        `?idCompetition=${idCompetition}` +
        `&idEdition=${idEdition}` +
        `&idTeam=${idTeam}`
    )
  );

const getRoundList = async (idEdition, idCompetition) =>
  parseRoundList(
    await getAPI(
      "/Matchweeks/lite" +
        `?idCompetition=${idCompetition}` +
        `&idEdition=${idEdition}`
    )
  );

const getWeekList = async (idEdition, idCompetition) =>
  parseWeekList(
    await getAPI(
      "/Weeks/lite" +
        `?idCompetition=${idCompetition}` +
        `&idEdition=${idEdition}`
    )
  );

const getCurrentRound = (idEdition, idCompetition) =>
  getAPI(
    "/Matchweeks/current" +
      `?idCompetition=${idCompetition}` +
      `&idEdition=${idEdition}`
  );

const getCurrentWeek = (idEdition, idCompetition) =>
  getAPI(
    "/Weeks/current" +
      `?idCompetition=${idCompetition}` +
      `&idEdition=${idEdition}`
  );

const getCompetitionList = async (year) =>
  parseCompetitionList(await getAPI("/Editions" + `?year=${year}`));

const getYearList = async () =>
  parseYearList(await getAPI("/Editions" + `?order_direction=1`));

const getStandings = async (idEdition, idCompetition, idMatchweek, idPhase) =>
  parseStandings(
    await getAPI(
      "/Standings" +
        `?idCompetition=${idCompetition}` +
        `&idEdition=${idEdition}` +
        `&idPhase=${idPhase}` +
        `&idMatchweek=${idMatchweek}`
    )
  );

const getActualStandings = async () =>
  parseStandings(await getAPI("/Standings/current"));
// const getActualStandings = async () =>
// parseStandings(
//   await getAPI(
//     "/Standings" +
//       `?idCompetition=1` +
//       `&idEdition=86` +
//       `&idPhase=107` +
//       `&idMatchweek=5420`
//   )
// );

export default {
  getActual,
  getActualFromMatch,
  getMatchList,
  getRoundList,
  getWeekList,
  getCurrentRound,
  getCurrentWeek,
  getCompetitionList,
  getYearList,
  getStandings,
  getActualStandings,
  getMatchListByWeek,
  getMatchListByTeam,
  getAllMatches,
};

const parseActualRound = (data) => {
  if (!data.length) {
    return [];
  }
  return {
    actual: {
      year: data[0].edition.year,
      competition: {
        id: data[0].id_competition,
      },
      edition: {
        id: data[0].id_edition,
      },
      round: {
        id: data[0].id,
        matchweek_number: data[0].num_matchweek,
        descriptor: data[0].matches[0].matchweek_descriptor,
      },
      week: {
        id: 12,
        start_date: 19283746,
      },
      phase: {
        id: data[0].id_phase,
      },
    },
    competitionImg: data[0].competition.url_image_negative,
    competitionName: data[0].competition.name,
  };
};

const parseActualWeek = (data) => {
  return {
    actual: {
      year: data.edition.year,
      competition: {
        id: data.id_competition,
      },
      edition: {
        id: data.id_edition,
      },
      round: {
        id: data.partidos_list[0].id_matchweek,
        matchweek_number: data.partidos_list[0].matchweek_number,
        descriptor: data.partidos_list[0].matchweek_descriptor,
      },
      week: {
        id: data.id,
        start_date: data.start_date,
      },
      phase: {
        id: data.id_phase,
      },
    },
    competitionImg: data.competition.url_image_negative,
    competitionName: data.competition.name,
  };
};

const parseActualFromMatch = (data) => {
  if (data) {
    return {
      actual: {
        year: data.edition.year,
        competition: {
          id: data.id_competition,
        },
        edition: {
          id: data.id_edition,
        },
        round: {
          id: data.id_matchweek,
          matchweek_number: data.matchweek_number,
          descriptor: data.matchweek_descriptor,
        },
        week: {
          id: data.id_week,
          start_date: data.week_start_date,
          description: data.description,
        },
        phase: {
          id: data.id_phase,
        },
      },
      competitionImage: data.competition.url_image_negative,
      competitionName: data.competition.official_name,
    };
  } else {
    return null;
  }
};

const parseYearList = (data) => {
  let yearList = [];
  for (let edition of data) {
    if (!yearList.includes(edition.year)) {
      yearList.push(edition.year);
    }
  }
  return yearList.sort().reverse();
};

const parseCompetitionList = (data) => {
  return data;
};

const parseRoundList = (data) => {
  return data.sort(function(b, a) {
    return a.num_matchweek - b.num_matchweek;
  });
};
const parseWeekList = (data) => {
  if (data) {
    return data.sort(function(a, b) {
      return b.start_date - a.start_date;
    });
  }
};

const parseMatchList = (data) => {
  for (let match of data) {
    for (let img of match.local_team.media) {
      if (img) {
        if (img.type == "logo") {
          match["local_image"] = getUrlResizedRatio(img, img.id_type);
        }
        if (img.type == "logo_negativo") {
          match["local_negative_image"] = getUrlResizedRatio(img, img.id_type);
        }
      }
    }
    for (let img of match.visitor_team.media) {
      if (img) {
        if (img.type == "logo") {
          match["visitor_image"] = getUrlResizedRatio(img, img.id_type);
        }
        if (img.type == "logo_negativo") {
          match["visitor_negative_image"] = getUrlResizedRatio(
            img,
            img.id_type
          );
        }
      }
    }
  }
  return data.reverse();
};

const parseStandings = (data) => {
  if (data.length) {
    for (let el in data) {
      let competition = data[el];
      if (competition.id_competition == 1) {
        for (let club of competition.standings) {
          if (club.victories_percentage == null) {
            club["victories_percentage"] = 0;
          }
          for (let img of club.team.media) {
            if (img != null) {
              if (img.type == "logo") {
                club["image"] = getUrlResizedRatio(img, img.id_type);
              }
            }
          }
        }
        return competition.standings;
      }
    }
    return data[0].standings;
  }
  return data;
};
