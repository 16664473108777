import { mediacenter_types } from "@/api/config";

function getUrlResizedRatio(img, id_type) {

  var url = img.url;
  var url_format_image = url.split(".");

  if (url_format_image.at(-1) == "jpg"){

    var url_array = url.split("/");
    var url_mid = url_format_image[2].split("/");
    var url_mid_features = url_mid.at(-1);
    var features = url_mid_features.split("_");

    if (url_mid[1] == "media" || url_mid[2] == "media") {

      var element = getFeaturesFromId(id_type);
      var ratio = element.ratio
      var size = element.size

      if (features.length == 1) {

        if (ratio !== ""){
          var features_ratio = [features[0], ratio, features[1]];
        }

        else{
          features_ratio = [features[0], features[1]];
        }
      }

      if (features.length == 2) {
        if (ratio !== ""){
          features_ratio = [features[0], ratio, size];
        }

        else{
          features_ratio = [features[0], size];
        }
      }

      if (features.length == 3){
        return url
      }

      else if (features.length >= 3){
        return url;
      }

      url_array.pop();
      url_array.push(features_ratio.join("_"));
      var url_fixed = url_array.join("/");
      var url_final = [url_fixed, "jpg"].join(".");
      return url_final;

    }
    return url
  }
  return url
}

function getImageFormat(image_url){

  var url_format_image = image_url.split(".");
  return url_format_image.at(-1);

}

function getMediaCenterUrl(img) {

  var format = getImageFormat(img.url);

  if (format == "jpg") {

    var img_mediacenter_url = getUrlResizedRatio(img, img.id_type);
    return img_mediacenter_url;

  }
  else if (format == "png") {

      return img.url;
  }
}

function getFeaturesFromId(id_type) {
  for (let element of mediacenter_types) {
    if (element.id_type == id_type){
      return element
    }
  }
  return({
    id_type: 1,
    type: "foto_cuerpo",
    ratio: "",
    size: "04"
  })
}

export { getMediaCenterUrl, getUrlResizedRatio };
